<template>
  <div class="combo page">
    <div class="header app-padding-bottom">
      <h1 class="page-title">YOUTOO爱听说</h1>
      <div class="card-container">
        <van-cell-group class="card" :border="false">
          <van-field
            v-model="accountInfo.account"
            required
            clearable
            label="账号"
            label-width="3em"
            right-icon="warning-o"
            placeholder="请输入爱听说账号"
            @click-right-icon="showTip"
            @input="accountValidationStatus = false"
            @blur="validateAccount"
          />
          <van-field
            v-model="accountInfo.name"
            required
            clearable
            label="姓名"
            label-width="3em"
            placeholder="请输入姓名"
            @input="accountValidationStatus = false"
            @blur="validateAccount"
          />
        </van-cell-group>
      </div>
    </div>
    <transition mode="out-in" name="zoom-in">
      <van-empty
        v-if="!accountValidationStatus"
        description="套餐信息在爱听说账号验证成功后可见"
      />
      <div v-else class="card-container">
        <van-tabs
          class="card"
          animated
          swipeable
          color="#2387f5"
          title-active-color="#2387f5"
          line-width="20px"
          line-height="4px"
        >
          <van-tab title="口语训练套餐">
            <template #title>
              <van-icon name="gem-o" style="vertical-align: middle" />
              口语训练套餐
            </template>
            <van-cell-group class="app-margin-top card" :border="false">
              <template v-if="!loading">
                <van-cell
                  v-for="goods in tongueCombo"
                  is-link
                  center
                  value-class="price"
                  :key="goods.id"
                  :title="goods.name"
                  :value="`￥${goods.price.toFixed(2)}`"
                  @click="toBuy('tongue', goods)"
                />
              </template>
              <template v-else>
                <loading height="20vh" size="36px" />
              </template>
            </van-cell-group>
          </van-tab>
          <van-tab title="训练二级套餐">
            <template #title>
              <van-icon name="service-o" style="vertical-align: middle" />
              训练耳机套餐
            </template>
            <van-cell-group class="app-margin-top card" :border="false">
              <template v-if="!loading">
                <van-cell
                  v-for="goods in headsetCombo"
                  is-link
                  center
                  value-class="price"
                  :key="goods.id"
                  :title="goods.name"
                  :value="`￥${goods.price.toFixed(2)}`"
                  @click="toBuy('headset', goods)"
                />
              </template>
              <template v-else>
                <loading height="20vh" size="36px" />
              </template>
            </van-cell-group>
          </van-tab>
        </van-tabs>
      </div>
    </transition>
  </div>
</template>

<script>
  export default {
    name: 'ComboBuyTab',
    data() {
      return {
        accountInfo: {
          account: '',
          name: '',
        },
        loading: false,
        accountValidationStatus: false,
      };
    },
    computed: {
      tongueCombo() {
        return this.fetchedData.tongueCombo || [];
      },
      headsetCombo() {
        return this.fetchedData.headsetCombo || [];
      },
    },
    async created() {
      this.loading = true;
      try {
        await this.$fetchData({
          tongueCombo: this.$request('comboBuy/fetchGoods', {
            typeId: '1320984315795189762',
          }),
          headsetCombo: this.$request('comboBuy/fetchGoods', {
            typeId: '1320984369616498689',
          }),
        });
      } finally {
        this.loading = false;
      }
    },
    methods: {
      async validateAccount() {
        const account = this.$trim(this.accountInfo.account);
        const name = this.$trim(this.accountInfo.name);
        if (!account || !name) {
          return;
        }
        const loadingInstance = this.$toast.loading({
          message: '账号验证中...',
          forbidClick: true,
          duration: 0,
        });
        try {
          const { data } = await this.$request('comboBuy/fetchAccountInfo', {
            account,
            name,
          });
          loadingInstance.clear();
          if (!data) {
            // 账号不存在
            this.$toast({
              message: '账号不存在',
              icon: 'warning-o',
            });
            return;
          }
          this.$toast.success({
            message: '验证成功',
          });
          Object.assign(this.accountInfo, data);
          this.accountValidationStatus = true;
        } catch {
          loadingInstance.clear();
          this.$toast.fail({
            message: '验证失败',
          });
        }
      },
      showTip() {
        this.$dialog.alert({
          title: '温馨提示',
          message: '账号为线下教育所使用的爱听说账号！',
        });
      },
      toBuy(type, goods) {
        // 购买前需要验证账号信息
        if (!this.accountValidationStatus) {
          this.$dialog.alert({
            title: '温馨提示',
            message: '请先输入账号！',
          });
          return;
        }
        this.$setData('currentGoods', goods);
        this.$setData('currentAccount', this.accountInfo);
        this.$router.push({
          path: '/buy-detail',
          query: {
            type,
          },
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .header {
    background-color: #2387f5;
    background-image: url('~@/assets/bg.png');
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: 100%;

    .page-title {
      color: #fff;
    }
  }

  .zoom-in-enter-active,
  .zoom-in-leave-active {
    transition: transform 0.6s cubic-bezier(0.34, 1.56, 0.64, 1),
      opacity 0.6s cubic-bezier(0.34, 1.56, 0.64, 1);
  }

  .zoom-in-enter,
  .zoom-in-leave-to {
    opacity: 0;
    transform: scale(0.7, 0.7);
  }
</style>
